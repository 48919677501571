
export const rendererStyles = {
    iconButton: {
        padding: 0,
        margin: 0,
    },
    icon: {
        fontSize: "22px",
    }
}
