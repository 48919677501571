import React, {useCallback, useContext, useEffect, useMemo, useState} from "react"
import {groupStyles} from "../index";
import Box from "@mui/material/Box";
import _ from "lodash";
import {ViewItem} from "../../../../data/state";
import {MainDataContext} from "../../../../App";
import FacilityView from "./facility"
import TreeView from "./tree"
import Typography from "@mui/material/Typography";
import DateSelectorComponent from "../../components/dateSelector";
import IconButton from "@mui/material/IconButton";
import {Cached as CachedIcon} from "@mui/icons-material";
import GroupItemSelectorComponent from "../../components/groupItemSelector";
import {AgGridReact} from "ag-grid-react";
import {columnDefs, defaultColDef} from "../record/column";
import useApiManager from "../../../../manager/api2";

const styles = {
    ...groupStyles,
}

const ViewItems = [
    {title: "設備一覧", viewItem: ViewItem.Master},
    {title: "樹木一覧", viewItem: ViewItem.Tree},
]

const MasterGroupView = (props) => {

    const { state, setViewItem } = useContext(MainDataContext)
    const { Get } = useApiManager()
    const [rowData, setRowData] = useState()

    useEffect(() => {

        if(!_.find(ViewItem, (v) =>
            v.viewItem === state.viewItem
        )) {
            setViewItem(ViewItem.Master)
        }

    }, [state.viewGroup])

    useEffect(() => {
        load()
    }, [state.viewItem])

    const title = useMemo(() => {
        if (!state.viewItem) { return }
        return ViewItems.find(v => v.viewItem === state.viewItem)?.title
    }, [state.viewItem])

    const load = useCallback(() => {
        Get(`system/${state.viewItem}`)
            .then(setRowData)
            .catch(e => {
                console.log(e)
            })
    }, [state.viewItem])

    return (
        <Box style={{display: "flex", flexDirection: "column", width: "100%", height: "100%", position: "relative"}}>
            <Box style={styles.itemBox}>
                <Typography style={styles.title}>{title}</Typography>
                <Box style={{flexGrow: 1}}/>
                <IconButton onClick={() => load(true)}>
                    <CachedIcon />
                </IconButton>
                <GroupItemSelectorComponent items={ViewItems} onSelect={setViewItem} selected={state.viewItem} />
            </Box>
            {!rowData && <Box><Typography>読込中...</Typography></Box>}
            {rowData && state.viewItem === ViewItem.Master && <FacilityView data={rowData} />}
            {rowData && state.viewItem === ViewItem.Tree && <TreeView data={rowData} />}
        </Box>
    )

}

export default MasterGroupView
