import React, {useMemo} from "react"
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {Checkbox} from "@mui/material";
import useApiManager from "../../../../../../../manager/api2";

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        margin: '1rem',
        alignItems: "center",
    },
    itemBox: {
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        marginRight: "1rem",
        alignItems: "center",
    },
    title: {
        color: "#808080",
    },
    value: {
        fontSize: "18px",
    }
}

const ReportUserSummaryHeaderView = React.memo(({data, onChange}) => {

    const {Put} = useApiManager()

    const openTime = useMemo(() => {
        if (!data?.summary_data?.work_time) { return "未登録"}
        let d = dayjs(data.summary_data.work_time.open_time)
        if (d.isValid()) {
            return d.format("HH時mm分")
        }
        return "不明"
    }, [data])

    const closeTime = useMemo(() => {
        if (!data?.summary_data?.work_time) { return "未登録" }
        let d = dayjs(data.summary_data.work_time.close_time)
        if (d.isValid()) {
            return d.format("HH時mm分")
        }
        return "不明"
    })

    const onAdminApproved = () => {
        Put("report/daily_report_approved", {
            daily_report_id: data.daily_report_id,
        }).then(onChange)
            .catch(e => {
                console.log(e)
            })
    }

    return (
        <Box style={styles.root}>
            <Box style={styles.itemBox}>
                <Typography variant="h6">{data.user.user_name}さんの{dayjs(data.day).format("YYYY年M月D日(ddd)")}の日次報告</Typography>
            </Box>
            <Box style={styles.itemBox}>
                <Typography style={styles.title}>天候</Typography>
                <Typography style={styles.value}>{data.user.weather ?? "未登録"}</Typography>
            </Box>
            <Box style={styles.itemBox}>
                <Typography style={styles.title}>体調</Typography>
                <Typography style={styles.value}>{data.user.health ? "良好": "不調"}</Typography>
            </Box>
            <Box style={styles.itemBox}>
                <Typography style={styles.title}>作業開始</Typography>
                <Typography style={styles.value}>{openTime}</Typography>
            </Box>
            <Box style={styles.itemBox}>
                <Typography style={styles.title}>作業終了</Typography>
                <Typography style={styles.value}>{closeTime}</Typography>
            </Box>
            <Box style={styles.itemBox}>
                <Typography style={styles.title}>マネージャー確認</Typography>
                <Checkbox size="small" checked={data.approve} onClick={onAdminApproved} />
            </Box>
        </Box>
    )
})

ReportUserSummaryHeaderView.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default ReportUserSummaryHeaderView
