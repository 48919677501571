import dayjs from "dayjs";

export const ColumnDefs = [
    {
        field: "group_key",
        headerName: "グループ",
        rowGroup: true,
        hide: true,
    },
    {
        field: "facility_title",
        headerName: "遊具・施設名"
    },
    {
        field: "value",
        headerName: "数値",
    },
    {
        cellDataType: "boolean",
        headerName: "異常有無",
        valueGetter: (params) => {
            if (["屋外遊具", "あそびの里", "水遊具", "施設", "地点報告"].includes(params.data?.type)) {
                return null
            }
            return !!params.data?.incident_id
        },
    },
    {
        field: "report_text",
        headerName: "状態",
    },
    {
        field: "repair_text",
        headerName: "処置",
    },
    {
        field: "latest_status_name",
        headerName: "現在のステータス",
    },
    {
        field: "checkin_at",
        headerName: "IN・点検時刻",
        valueFormatter: (params) => {
            if (!params.value) { return null }
            return dayjs(params.value).format("HH:mm")
        },
    },
    {
        field: "checkout_at",
        headerName: "OUT時刻",
    },
]
