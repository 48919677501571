import React from "react"
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const IncidentReportMapView = React.memo(({lngLat}) => {

    return (
        <Box>
            地図画面
        </Box>
    )

})

IncidentReportMapView.propTypes = {
    lngLat: PropTypes.any.isRequired,
}

export default IncidentReportMapView
