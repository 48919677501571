import React from "react"
import Box from "@mui/material/Box";
import {AgGridReact} from "ag-grid-react";
import { ColumnDefs, DefaultColDef} from "./column"
import PropTypes from "prop-types";

const MasterGroupFacilityView = React.memo(({data}) => {


    return (
        <AgGridReact
            className={'ag-theme-balham'}
            columnDefs={ColumnDefs}
            defaultColDef={DefaultColDef}
            rowData={data}
        />
    )
})

MasterGroupFacilityView.propTypes = {
    data: PropTypes.array.isRequired,
}

export default MasterGroupFacilityView
