import React from "react"
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const ReportGroupTrackingMapView = React.memo((props) => {

    return (
        <Box>マップ</Box>
    )
})

ReportGroupTrackingMapView.propTypes = {
    data: PropTypes.array.isRequired,
}

export default ReportGroupTrackingMapView
