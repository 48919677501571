import React, {useCallback, useEffect, useRef, useState} from "react"
import PropTypes from "prop-types";
import {ToggleButtonGroup, ToggleButton} from "@mui/material"
import Box from "@mui/material/Box";
import useApiManager from "../../../../../manager/api2";
import Typography from "@mui/material/Typography";
import {EVENT_REPORT_DETAIL_UPDATED} from "./index";

const styles = {
    root: {
        display: "flex",
        justifyContent: "end",
    }
}


const IncidentReportInfoStatusView = React.memo(({data, statuses, onChange}) => {
    const { Get, Put } = useApiManager()

    const onToggleChange = useCallback(e => {
        Put(`report/incident2/${data.incident_uid}`, {
            status: e.target.value,
        }).then(() => {
            onChange()
            window.dispatchEvent(new CustomEvent(EVENT_REPORT_DETAIL_UPDATED))
        }).catch(e => {
            console.log(e)
        })
    }, [data, onChange])

    return (
        <Box style={styles.root}>
            {data && (
            <ToggleButtonGroup value={[data.status]} onChange={onToggleChange} size="small">
                {statuses.filter(v => !v.admin_select_hidden).map(s =>
                    <ToggleButton
                        style={{
                            minWidth: "60px",
                            ...(!s.admin_select ? {cursor: "not-allowed"} : null),
                        }}
                        unselectable={true}
                        key={`toggle_button_${s.incident_status_id}`}
                        value={s.incident_status_id}>
                        {s.short_admin_name ?? s.admin_name ?? s.name}
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
                )}
            {!data && (
                <Typography>読込中...</Typography>
            )}
        </Box>
    )
})

IncidentReportInfoStatusView.propTypes = {
    data: PropTypes.object,
    statuses: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default IncidentReportInfoStatusView
