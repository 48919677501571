
export const ColumnDefs = [
    {
        field: "type_name",
        headerName: "グループ",
        rowGroup: true,
        width: 50,
        hide: true,
    },
    {
        field: "item_name",
        headerName: "内容",
        width: 300,
    },
    {
        headerName: "巡視員確認",
        field: "measured_at",
        cellDataType: "boolean",
        valueGetter: (params, value) => {
            console.log(params.data?.measured_at)
            return !!params.data?.measured_at
        }
    },
    {
        field: "",
        headerName: "開始時刻",
    },
    {
        field: "",
        headerName: "終了時刻",
    },
]
