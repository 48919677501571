import React from "react"
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {AgGridReact} from "ag-grid-react";
import {ColumnDefs} from "./column"
import {DefaultColDef} from "../index";

const styles = {
    root: {
        width: '100%',
        height: '300px',
    }
}

const ReportUserSummaryCheckView = React.memo(({data}) => {

    return (
        <Box style={styles.root}>
            <AgGridReact
                className={'ag-theme-balham'}
                columnDefs={ColumnDefs}
                rowData={data}
                defaultColDef={DefaultColDef}
            />
        </Box>
    )
})

export default ReportUserSummaryCheckView
