import React, {useEffect, useMemo, useState} from "react"
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import OtherReportView from "./other"
import CheckReportView from "./check"
import WorkReportView from "./work"
import HeaderView from "./header"
import dayjs from "dayjs";
import _ from "lodash";

const styles = {
    root: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "90%",
        minWidth: "600px",
        overflow: "auto",
        flexGrow: 1,
    },
    headerBox: {
        display: 'flex',
        flexDirection: 'row',
        margin: '1rem',
        alignItems: "center",
    },
    headerItemBox: {
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        marginRight: "1rem",
        alignItems: "center",
    }
}

export const DefaultColDef = {
    resizable: true,
}

const ReportGroupUserSummaryView = ({data, onChange}) => {

    const [editedWorkReport, setEditedWorkReport] = useState(data.summary_data.work_report)
    const [editedCheckReport, setEditedCheckReport] = useState(data.summary_data.check_report)
    const [editedOtherReport, setEditedOtherReport] = useState(data.summary_data.other_report)
    const [dataChanged, setDataChanged] = useState(false)

    useEffect(() => {

        let changed = false
        if (!_.isEqual(data.work_report, editedWorkReport)) {
            changed = true
        }
        if (!_.isEmpty(data.check_report, editedCheckReport)) {
            changed = true
        }
        if (!_.isEmpty(data.other_report, editedOtherReport)) {
            changed = true
        }

        if (changed) {
            setDataChanged(true)
        }

    }, [dataChanged])

    return (
        <Box style={styles.root}>
            <HeaderView data={data} onChange={onChange} />
            <OtherReportView data={editedOtherReport} onChange={setEditedOtherReport} />
            <WorkReportView data={editedWorkReport} onChange={setEditedWorkReport} />
            <CheckReportView data={editedCheckReport} onChange={setEditedCheckReport} />
        </Box>
    )

}

ReportGroupUserSummaryView.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default ReportGroupUserSummaryView
