import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import {OpenInNew as OpenInNewIcon} from "@mui/icons-material";


export const ColumnDefs = [
    {
        field: "measured_at",
        headerName: "時刻",
        valueGetter: (params) => {
            console.log('Time', params)
            return dayjs(params.data.measured_at).format("HH:mm")
        }
    },
    {
        field: "zone_name",
        headerName: "ゾーン",
    },
    {
        field: "nearest_facility_code",
        headerName: "施設コード"
    },
    {
        field: "nearest_facility_name",
        headerName: "施設名",
    },
    {
        field: "nearest_facility_distance",
        headerName: "施設までの距離",
        valueGetter: (params) => {
            if (!params.value) { return null }
            let l = parseInt(params.value)
            if (l > 10000) {
                return `${parseInt(l / 1000).toLocaleString()}km`
            }
            if (l > 1100) {
                return `${(l / 1000).toFixed(1).toLocaleString()}km`
            }
            return `${l.toLocaleString()}m`
        }
    },
    {
        headerName: "地図",
        cellRenderer: (params) => {
            return (
                <IconButton size="small" onClick={() => window.open(`https://maps.google.com/maps?q=${params.data.lat},${params.data.lon}`)}>
                    <OpenInNewIcon style={{fontSize: "12px"}} />
                </IconButton>
            )
        }
    },
]

export const DefaultColDef = {
    resizable: true,
}
