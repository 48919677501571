
export const ColumnDef = [
    {
        field: "item_name",
        headerName: "項目",
    },
    {
        field: "text",
        headerName: "内容",
    }
]
